var confirmPanel = {
    divId: 'confirmPanel',
    onSaveFunction: null,
    onCancelFunction: null,
    confirm: function (content, onSaveFunction, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }
        var convertedContent = strBR(content);
        dynamic.showOverlay('confirmPanelOverlay', 30);
        dynamic.setDivIdContent('confirmPanelContent', convertedContent);

        //
        content = '';
        var cancelText = 'Cancel';
        if (hasValue(options.cancelText)) {
            cancelText = options.cancelText;
        }
        content += tags.a(cancelText, 'javascript:confirmPanel.cancelClicked()', {
            cssClass: 'button cancel'
        });
        var confirmText = 'OK';
        if (hasValue(options.confirmText)) {
            confirmText = options.confirmText;
        }
        content += tags.a(confirmText, 'javascript:confirmPanel.saveClicked()', {
            cssClass: 'button save'
        });
        dynamic.setDivIdContent('confirmPanelButtons', content);

        //
        this.onSaveFunction = onSaveFunction;
        var top = $(window).scrollTop() + 100;
        dynamic.centerDiv(this.divId);
        $('#' + this.divId).css({
            top: top + 'px'
        });
        $('#' + this.divId).fadeIn();
        var tempThis = this;
        $(window).keyup(function (e) {
            tempThis.onkeyup(e);
        });
    },
    close: function () {
        this.onclose();
        $('#' + this.divId).fadeOut(null, function () {
            // dynamic.setDivIdContent('confirmPanelContent', '');
        });
        dynamic.hideOverlay('confirmPanelOverlay');
        $(window).unbind('keyup');
    },
    saveClicked: function () {
        if (this.onSaveFunction != null) {
            this.onSaveFunction();
        }
        this.close();
    },
    cancelClicked: function () {
        this.close();
    },
    onkeyup: function (event) {
        if (isEnterEvent(event)) {
            this.cancelClicked();
        }
    },
    onclose: function () {
        // event handlers
    }
};
